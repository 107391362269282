import React, { useEffect } from 'react'
import { 
    Badge,
    Tabs,
    Button,
    ButtonGroup,
    Card,
    Form,
    Layout,
    Page,
    Icon,
    TextStyle,
    Heading,
    Subheading,
    TextContainer,
    FormLayout,
  } from "@shopify/polaris"
import styled from 'styled-components'
import { navigate } from "gatsby"
const CenterScreeenWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 60vh;
    .is-complete::before {
            content: "✓";
            display: block;
            margin: 0 auto;
            width: 96px;
            height: 96px;
            text-align: center;
            line-height: 96px;
            border-radius: 100%;
            position: relative;
            z-index: 1;
            font-weight:300;
            font-size:6rem;
            color:  #15B68D;
            background:#F0F1F2;
            border:3px solid  #15B68D;
    }
    .success-text {
        background:#E0F2EE;
        border:3px solid #15B68D;
        padding: 18px 28px;
        color: #202123;
        font-weight: 400;
        margin-top: 35px;
        border-radius: 8px;
        font-size: 20px;
        line-height: 28px;
    }
`
function CreateOfferSuccess(props) {
    useEffect(() => {
        const timer = setTimeout(() => {
          navigate(
            `/app/onboardingLastStep`,
            {
              state: {
                backLink:"/app/offers", extensionName:"Checkout upsell"
              },
              replace: true,
            }
          )
        }, 2000);
        return () => clearTimeout(timer);
      }, []);
    return (
        <Page>
          <div style={{padding: '10px 0'}}>
            <h1 className="Polaris-Header-Title" style={{ display: 'inline'}}>Create Offer</h1>
          </div>
          <CenterScreeenWrapper>
            <div className="is-complete">
            </div>
            <div className='success-text'>
                <span>Upsell offer saved!</span>
            </div>
          </CenterScreeenWrapper>
        </Page>
        )
}

export default CreateOfferSuccess;